import { styled } from '@linaria/react'
import { BottomSheetOnRoute } from 'components/designSystem/BottomSheet/BottomSheetOnRoute'
import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { Typography } from 'components/designSystem/Typography/Typography'
import { shortcutListPath } from 'components/page/Boarding/boarding.paths'
import countiesList from 'components/page/Boarding/country-links.json'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLocale } from 'hooks/useLocale'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const CountriesBottomSheet: FC = () => {
  const locale = useLocale()
  const baseUrl = useBaseUrl()

  return (
    <BottomSheetOnRoute
      route={mergeAllUrls(baseUrl, shortcutListPath)}
      title={
        <FormattedMessage
          id="boarding.footer.countries"
          defaultMessage="Страны"
        />
      }
    >
      {_countiesList.map(({ name, to }, index) => {
        return (
          <Country key={to} fontSize={16} fontWeight={500}>
            <RouterLink
              key={index}
              to={`/${locale}/landing/${to}`}
              data-name={`link-${index}`}
              black
            >
              <FormattedMessage
                id="landing.title"
                values={{
                  locationName: <FormattedMessage id={name} />,
                }}
              />
            </RouterLink>
          </Country>
        )
      })}
    </BottomSheetOnRoute>
  )
}

const _countiesList = (countiesList as unknown) as {
  name: string
  to: string
}[]

const Country = styled(Typography)`
  padding: var(--spacing-16px, 16px) var(--spacing-none, 0px);
  text-align: left;

  & > * {
    color: var(--foreground-default, #2e2a29);
  }
`
